import type {
	InvariantRoutes,
	MatchedRoute,
	MatchedInvariantRoute,
	Query,
	Routes,
	Route,
} from '../../types.tsx';
import matchRouteOrderIndependent from '../match-route-order-independent/index.tsx';
import matchRouteOrderDependent from '../match-route-order-dependent/index.tsx';

const isLegacyRoutes = (routes: Routes): boolean => {
	// a naive way to check if the routes are legacy routes
	return routes[routes.length - 1]?.name === 'legacy-fallback';
};

export const matchInvariantRoute = (
	routes: InvariantRoutes,
	pathname: string,
	queryParams: Query | undefined,
	basePath = '',
): MatchedInvariantRoute | null => {
	const useOrderIndependentMatching = isLegacyRoutes(routes) === false;
	const matchRoute = useOrderIndependentMatching
		? matchRouteOrderIndependent
		: matchRouteOrderDependent;
	return matchRoute(routes, pathname, queryParams, basePath);
};

/**
 * Performance optimisation to fast-match a single route
 * instead of looping thorugh all defined routes
 */
export const warmupMatchRouteCache = (
	route: Route,
	pathname: string,
	queryParams: Query | undefined,
	basePath = '',
) => {
	// no need to check legacy routes because `warmupMatchRouteCache` is used for SPA only
	return matchRouteOrderIndependent([route], pathname, queryParams, basePath);
};

const defaultMatchRoute = (
	routes: Routes,
	pathname: string,
	queryParams: Query | undefined,
	basePath = '',
): MatchedRoute | null => {
	const useOrderIndependentMatching = isLegacyRoutes(routes) === false;

	const matchRoute = useOrderIndependentMatching
		? matchRouteOrderIndependent
		: matchRouteOrderDependent;
	const matchedRoute = matchRoute(routes, pathname, queryParams, basePath);

	return matchedRoute;
};

export default defaultMatchRoute;
